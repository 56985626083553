.ant-dropdown-menu {
  left: -12px;
  border-radius: 10px;
  padding: 0;

  li {
    padding: 0;

    &:last-of-type {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  a {
    font-weight: 500;
    font-size: 16px;
    line-height: 48px;
    padding: 0 16px;
    color: #999FAE;
  }
}

.comingModal {
  .ant-modal-content {
    height: 240px;
    display: flex;
    justify-content: center;
    box-shadow: -5px -5px 10px rgba(229, 229, 229, 0.25), 5px 5px 10px rgba(229, 229, 229, 0.25);
    border-radius: 20px;

    img {
      width: 108px;
      margin: 0 auto;
      display: block;
    }

    p {
      color: #0B1CB5;
      font-size: 24px;
      font-weight: 500;
    }

    .ant-btn {
      margin: 0 auto;
      display: block;
      border-radius: 15px;
      width: 160px;
      height: 48px;
    }
  }
}

.ant-layout-header {
  position: fixed;
  top: 0;
  z-index: 1;
  width: 100%;
  line-height: 100px !important;
  background: transparent;
  height: auto !important;
  @media (max-width: 767px) {
    height: 104px !important;
    line-height: 64px !important;
  }
  padding: 0 !important;
}

.header {
  width: 100%;
  display: flex;
  // grid-template-areas: "logo nav buildDao";
  justify-content: space-between;
  align-items: center;

  .Nav {
    display: flex;
    grid-area: nav;
    grid-template-columns: repeat(8, auto);
    align-items: center;
    justify-content: space-between;
    // margin-right: -18px;
    // width: 50%;
    width: 600px;
    height: 54px;
    border:  1px solid #FFFFFF14;
    border-radius: 32px;
    background: #141639;
    @media (max-width: 767px) {
      margin-right: 0;
    }

    .ant-dropdown-link {
      line-height: 40px;

      &:hover {
        color: #000;

        svg {
          fill: #0B1CB5;
        }
      }
    }

    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      box-shadow: none;
    }

    .ant-select {
      font-size: 16px;
      color: #999FAE;

      &-dropdown {
        width: auto !important;
      }

      &-selection-item {
        line-height: 32px;
      }

      &-selector {
        border: none;

      }
    }
  }

  .Logo {
    float: left;
    width: auto;
    height: 40px;
    margin: 30px 0;
    &:hover{
      cursor: pointer;
    }
    @media (max-width: 767px) {
      margin: 15px 0;
      height: 30px;
    }
    grid-area: logo;
  }

  .Nav a {
    height: 52px;
    padding: 0 16px;
    font-size: 16px;
    font-weight: 500;
    transition: 0.5s;
    text-decoration: none;
    line-height: 52px;
    color: #B2B2B2;

    svg {
      fill: #999FAE;
      margin-left: 9px;
    }

    &.active {
      border-radius: 32px;
      color: #FFFFFF;
      background: #171719;
    }
  }
  .BuildDao {
    margin-right: 20px;
    grid-area: buildDao;
    @media (max-width: 767px) {
      display: none;
    }
  }

  .Burger {
    display: none;
    grid-area: burger;
    margin: 0;
    padding: 0;
    justify-self: end;
    font-size: 30px;
    border: none;
    background: none;
    outline: none;
    transition: 0.1s;
  }

  @media (max-width: 767px) {
    .Nav {
      grid-template-rows: repeat(8, auto);
      grid-template-columns: none;
      grid-row-gap: 10px;
      padding: 0;
      background: #fff;
      color: #999FAE;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      width: 100%;
      position: absolute;
      top: 104px;
      left: 0;
      padding-bottom: 10px;
    }

    .Burger {
      display: inline;
      mix-blend-mode: difference;
      svg path {
          fill: #fff
        }
    }
  }

  .NavAnimation-enter {
    opacity: 0;
    transform: scale(0.5);
  }

  .NavAnimation-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 350ms, transform 350ms;
  }

  .NavAnimation-exit {
    opacity: 1;
  }

  .NavAnimation-exit-active {
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 350ms, transform 350ms;
  }

}

@media (max-width: 767px) {
  .header {
    display: grid;
    width: 100vw;
    grid-template-areas: "logo burger" "nav nav";
  }

  .Nav a {
    height: 40px;
    line-height: 40px;
    padding: 0;
  }
}
  
@primary-color: #5542F6;